import React from "react";

export default ({ children, title, phone, email, routeLink }) => {
    return (
        <>
            <h1 className="my-0 text-xl">{title}</h1>
            <ul className="text-2xl font-bold leading-snug my-gutter xl:text-3xl 2xl:text-4xl">
                <li>
                    <a
                        href={`tel:${phone.machine}`}
                        className="text-inherit hover:no-underline hover:text-gray-700"
                    >
                        {phone.human}
                    </a>
                </li>
                <li>
                    <a
                        href={`mailto:${email}`}
                        className="font-regular text-inherit hover:no-underline hover:text-dark"
                    >
                        {email}
                    </a>
                </li>
            </ul>
            <div>
                <address className="not-italic">{children}</address>
                {routeLink && (
                    <a
                        href={routeLink}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="inline-block mt-1 font-bold"
                    >
                        Route mit Google Maps
                    </a>
                )}
            </div>
        </>
    );
};
