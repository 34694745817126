import React from "react";
import Address from "~/address";
import Column from "~/column";
import Container from "~/container";
import Layout from "~/layout";
import SEO from "~/seo";

const ContactPage = () => (
    <Layout fullHeight={true}>
        <Container spacing={true}>
            <Column>
                <h1 className="text-hero mb-xl md:mb-3xl">Kontakt</h1>
            </Column>

            <div className="space-y-3xl lg:flex lg:items-start lg:space-y-0">
                <Column className="lg:w-1/2">
                    <Address
                        title="Halver"
                        email="info@bueroschriever.de"
                        phone={{ human: "+49 (0) 2353 666 54 66", machine: "004923536665466" }}
                        routeLink="https://goo.gl/maps/LYhmuVueVaYjR5V26"
                    >
                        Bächterhof 4<br />
                        58553 Halver
                    </Address>
                </Column>
                <Column className="lg:w-1/2">
                    <Address
                        title="Siegen"
                        email="th@bueroschriever.de"
                        phone={{ human: "+49 (0) 271 7030 5101", machine: "004927170305101" }}
                        routeLink="https://goo.gl/maps/xG8yBQ9VN7P9RdCk8"
                    >
                        Europastraße 11
                        <br />
                        57072 Siegen
                    </Address>
                </Column>
            </div>
        </Container>
    </Layout>
);

export const Head = () => (
    <SEO
        title="Kontakt"
        description="Wir sind ein Einrichtungshaus mit Büros in Halver und Siegen. Kontaktieren Sie uns."
    />
);

export default ContactPage;
